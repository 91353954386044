/* global-styles.css */
#template-description h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

#template-description h3 {
  font-size: 1.25rem;
  font-weight: 700 !important;
  margin-top: 1.75rem;
  margin-bottom: 0.4rem;
}

#template-description h4 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
}

#template-description p {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

#template-description li {
  padding-left: 0.3rem;
  font-size: 1.125rem;
  margin-bottom: 0.7rem;
}

#template-description ol {
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1rem;
  margin-left: 1rem;
  margin-top: 0px;
}

#template-description ul {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1rem;
  margin-left: 2rem;
  margin-top: 1rem;
}

#template-description blockquote {
  margin-left: 1rem;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 400;
  font-style: italic;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  border-left: 3px solid rgba(0, 0, 0, 0.84);
  padding-left: 20px;
  padding-bottom: 2px;
}
