/* CustomEditor.css */

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e6e7eb;
  display: flex;
  justify-content: flex-start;
  background: rgba(0, 0, 0, 0);
  flex-wrap: wrap;
  font-size: 15px;
  user-select: none;
}

.rdw-editor-wrapper:focus {
  outline: none;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
}
.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}
.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

/* Style toolbar buttons */
.rdw-option-wrapper {
  border: 0px solid #f1f1f1;
  padding: 5px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 2px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  text-transform: capitalize;
  color: #333;
  transition: background-color 0.3s;
}
/* Style the icons in the editor toolbar */

.rdw-option-wrapper:hover {
  background-color: #e6e7eb; /* Slight gray background on hover */
  box-shadow: none; /* Remove box shadow on hover */
}

.rdw-option-wrapper:active {
  background-color: #e6e7eb; /* Slight gray background on click */
  box-shadow: none; /* Remove box shadow on click */
}

/* Style active toolbar buttons */
.rdw-option-active {
  background-color: #e6e7eb; /* Slight gray background when active */
  box-shadow: none; /* Remove box shadow on active */
}

/* Style dropdowns */
.rdw-dropdown-wrapper {
  min-height: 28px;
  background: white;
  cursor: pointer;
  border: 0px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 8px;
  text-transform: capitalize;
  background: white;
}

.rdw-dropdown-selectedtext {
  font-size: 14px;
  color: #333;
}

.rdw-dropdown-wrapper:focus {
  outline: none;
}

.rdw-dropdown-wrapper:hover {
  background-color: #f1f1f1; /* Slight gray background on hover */
  box-shadow: none; /* Remove box shadow on hover */
}

.rdw-dropdown-wrapper:active {
  background-color: #e0e0e0; /* Slight gray background on click */
  box-shadow: none; /* Remove box shadow on click */
}
.rdw-dropdownoption-default {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.rdw-dropdown-optionwrapper:hover {
  box-shadow: none !important;
  background-color: #ffffff;
}
/* ... (other styles) */

/* Custom styles for your specific components */
/* ... */

/* Style for the outer container of the editor */
.editor-container {
  font-family: "Roboto", sans-serif;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
}

/* Style for the editor wrapper */
.editor-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff;
}

/* Style for the editor content */
.editor-content {
  font-size: 16px;
  line-height: 1;
  padding: 0px 16px;
  min-height: 300px;
}

.public-DraftStyleDefault-block {
  margin-top: 0px !important;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 1rem;
}

.public-DraftStyleDefault-block {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: inherit;
  line-height: 1.5;
  --line-height: 1.5rem;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0px !important;
  padding: 0;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 1rem !important;
}

.rdw-editor-main blockquote {
  margin-left: 1rem;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  font-style: italic;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  border-left: 3px solid rgba(0, 0, 0, 0.84);
  padding-left: 20px;
  padding-bottom: 2px;
}

.DraftEditor-root blockquote .public-DraftStyleDefault-block {
  margin-bottom: 0px !important;
}

.DraftEditor-root .public-DraftStyleDefault-ol,
.DraftEditor-root .public-DraftStyleDefault-ul {
  margin: 0px !important;
  padding: 0;
  margin-top: 0px !important;
  margin-left: 1.5rem !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  align-items: center;
}

.DraftEditor-root .public-DraftStyleDefault-orderedListItem {
  padding: 0;
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  font-size: 1rem;
  align-items: center;
}

.DraftEditor-root .public-DraftStyleDefault-orderedListItem:before {
  line-height: 1.5;
  --line-height: 1.5rem;
}

.DraftEditor-root .public-DraftStyleDefault-ol > li {
  padding: 0;
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  font-size: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.DraftEditor-root .public-DraftStyleDefault-ul > li {
  padding: 0;
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  font-size: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.DraftEditor-root h1 .public-DraftStyleDefault-block {
  font-size: 1.5rem;
  line-height: 1.3;
  --line-height: 1.95rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.DraftEditor-root h2 .public-DraftStyleDefault-block {
  font-size: 1.25rem;
  line-height: 1.6;
  --line-height: 2rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.DraftEditor-root h3 .public-DraftStyleDefault-block {
  font-size: 1.15rem;
  line-height: 1.5;
  --line-height: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.DraftEditor-root span .public-DraftStyleDefault-block {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.DraftEditor-root .editor-content p {
  margin: 0;
}
